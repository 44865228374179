





































































import { Vue, Component, Prop } from "vue-property-decorator";
import { TransferInfoProperties } from "./TransferInfoView.vue";
import DateUtil from '@/utilities/DateUtil';

@Component({})
export default class TransferSummaryView extends Vue {
    @Prop() private properties: TransferInfoProperties;

    public get request() {
        return this.properties.request;
    }

    public get currency() {
        return this.properties.currency;
    }

    public get totalAmount() {
        return this.request.totalAmount ?? 0;
    }

    public get isProcessing() {
        return this.request.status === "Processing";
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "-";
    }

    public async cancel() {
        await this.properties.events.fire("cancel");
    }
}
